
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

// model
import { VForm } from '@/model/Vuetify/VForm';
import { chatModule } from '@/store/entityModules/chat';

interface calendarDate{
  date: string;
}

interface DataType {
  selectedYear: number;
  selectedMonth:number;
  selectedDate: string;
  selectDate: string;
  yearList: Array<{
    yearNum: number;
    yearName: string;
  }>;
  monthList: Array<{
    monthNum: number;
    monthName: string;
  }>;
  dateList: Array<{
    date: string;
  }>;
  unReadDate: Array<{
    date: string;
  }>;
}

export default Vue.extend({
  name: 'CalendarModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
    calendar: {
      type: Array,
      default: () => ([]),
    },
    calendarDate: {
      type: Array as () => calendarDate[],
      required: true,
    },
  },
  computed: {
    form(): VForm {
      return (this.$refs as any).post_calendar_form;
    },
    now() {
      const len = this.calendarDate.length;
      this.selectedDate = this.calendarDate[len - 1].date;

      if (this.dateList.length != 0) {
        const length = this.dateList.length;
        this.selectedDate = this.dateList[length - 1].date
      }

      this.selectedYear = new Date(this.selectedDate).getFullYear();
      this.selectedMonth = new Date(this.selectedDate).getMonth();
      this.dateList = [];

      return dayjs(this.selectedDate).format('YYYY-MM-DD');
    },
    date() {
      const len = this.calendarDate.length;
      return this.calendarDate[len - 1].date; //現在、選択されている日付をreturnで返す
    },
    toDay() {
      return new Date();
    },
    //年度のセレクトアイテム (1950年～現在の年度+100年)
    selectYearItems() {
      const start = 1950;
      const end = this.toDay.getFullYear() + 100;
      for ( let i = start; i <= end; i++ ) {
        this.yearList.push({
          yearNum: i,
          yearName: `${i}年`,
        });
      }
      return this.yearList;
    },
    //月のセレクトアイテム (1月～12月)
    selectMonthItems() {
      const start = 0;
      const end = 11;
      for ( let i = start; i <= end; i++ ) {
        this.monthList.push({
          monthNum: i,
          monthName: `${i+1}月`,
        });
      }
      return this.monthList;
    },
  },

  watch: {
    isOpen: {
      async handler(val: boolean) {
        if (val) {
          this.selectedDate;
        }
      },
      deep: true,
    },
  },

  data: (): DataType => ({
    selectedYear: new Date().getFullYear(),
    selectedMonth: new Date().getMonth(),
    selectedDate: dayjs().format('YYYY-MM-DD'),
    selectDate: dayjs().format('YYYY-MM-DD'),
    yearList: [], //年度のセレクトアイテムを格納
    monthList: [], //月のセレクトアイテムを格納
    dateList: [],
    unReadDate: [],
  }),

  methods: {
    close() {
      this.dateList = [];
      this.unReadDate = [];
      this.$emit('close');
    },
    async fetchCalendar() {
      this.unReadDate = [];
      const date = new Date(this.selectedDate);
      const firstDate = dayjs(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format('YYYY-MM-DD');
      const lastDate = dayjs(firstDate).add(-1, 'day').format('YYYY-MM-DD');
      const num = new Date(lastDate).getDate(); //日数の取得

      const data = await chatModule.fetchCalendar({
        childId: this.childId,
        year: new Date(date).getFullYear(),
        month: Number(dayjs(date).format('MM')),
        date: `${num}`,
      });

      const arr = await Promise.all([data]);
      this.unReadDate = arr[0].data;
    },
    //選択した日付の年度を取得
    getSelectedYear(value: any) {
      const len = this.dateList.length;
      if (len == 0) {
        this.selectDate = this.selectedDate;
      } else {
        this.selectDate = this.dateList[len - 1].date;
      }

      const date = new Date(this.selectDate);
      this.selectedDate = dayjs(new Date(value.yearNum, date.getMonth(), date.getDate())).format('YYYY-MM-DD');
      this.dateList.push({
        date: this.selectedDate,
      });

      this.fetchCalendar();
    },
    //選択した日付の月を取得
    getSelectedMonth(value: any) {
      const len = this.dateList.length;
      if (len == 0) {
        this.selectDate = this.selectedDate;
      } else {
        this.selectDate = this.dateList[len - 1].date;
      }

      const date = new Date(this.selectDate);
      this.selectedDate = dayjs(new Date(date.getFullYear(), value.monthNum, date.getDate())).format('YYYY-MM-DD');
      this.dateList.push({
        date: this.selectedDate,
      });

      this.fetchCalendar();
    },
    //未読日の取得
    getUnReadDate(date) {
      const l = this.calendarDate.length;
      const calendarDate = this.calendarDate[l - 1].date;

      if (this.unReadDate.length == 0) {
        const len = this.calendar.length;
        for (let i = 0; i < len; i++) {
          if (date == this.calendar[i]) {
            if(calendarDate != this.calendar[i]){
            return 1; //未読がある場合はreturnで1を返す
          }
          }
        }
      } else {
        const length =this.unReadDate.length;
        for (let i = 0; i < length; i++) {
          if (date == this.unReadDate[i]) {
            return 1; //未読がある場合はreturnで1を返す
          }
        }
      }
    },
    //カレンダーで選択した日付を取得
    dateSelected(date: string) {
      const len = this.dateList.length;
      if (len != 0) {
        this.calendarDate.push({
          date: this.dateList[len - 1].date,
        });
      } else {
        this.calendarDate.push({
          date: date,
        });
      }

      this.dateList = [];
      this.unReadDate = [];
      this.$emit('select-date', date);
    },
  },

  async mounted() {
    this.fetchCalendar();
  },
});
