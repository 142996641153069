import ApiClient from '@/api/ApiClient';
import {
  ChatParentChoiceResponse,
  ChatChoiceResponse,
  HomeChatResponse,
  HomeChatListResponse,
  HomeAbsenceResponse,
  HomePickupResponse,
  HomeGroupChatListResponse,
} from '@API/src/component/home/chat/types';
import {
  FetchChatRequest,
  FetchChatsRequest,
  PostChatRequest,
  FetchHomeAbsenceRequest,
  PostHomeAbsenceRequest,
  FetchChatsChoicesRequest,
  FetchHomePickupRequest,
  PutHomePickupRequest,
  PostChatsConfirmRequest,
  DeleteChatsRequest,
  DeleteAbsenceRequest,
  DeletePickupRequest,
  FetchGroupChatsRequest,
} from '@/model/Chat';
import {
  isChatParentChoiceResponse,
  isChatChoiceResponse,
  isHomeChatResponse,
  isHomeChatsResponse,
  isHomeChatListResponse,
  isHomeAbsenceResponse,
  isHomeAbsencesResponse,
  isHomePickupResponse,
  isHomePickupsResponse,
  isHomeGroupChatListResponse,
} from '@/model/TypeGuard/Chat';

export default class ApiChat {

  public static async fetchChat(
    request: FetchChatRequest,
  ): Promise<HomeChatResponse> {
    try {
      const response = await ApiClient.get(`/chats/${request.chatId}`, request);
      if (response && isHomeChatResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchChats(
    request: FetchChatsRequest,
  ): Promise<HomeChatListResponse> {
    try {
      const response = await ApiClient.get(`/chats`, request);
      if (response && isHomeChatListResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchCalendar(input: {
    childId: number,
    year: number,
    month: number,
    date: string,
  }
  ) {
    try {
      const response = await ApiClient.get(`/chats/calendar`, input);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async fetchGroupChats(
    request: FetchGroupChatsRequest,
  ): Promise<HomeGroupChatListResponse> {
    try {
      const response = await ApiClient.get(`/group-chat`, request);
      if (response && isHomeGroupChatListResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postChats(
    request: PostChatRequest,
  ): Promise<HomeChatResponse> {
    try {
      const response = await ApiClient.post(`/chats`, request);
      if (isHomeChatResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchHomeAbsence(
    request: FetchHomeAbsenceRequest,
  ): Promise<HomeAbsenceResponse> {
    try {
      const response = await ApiClient.get(`/chats/absence`, request);
      if (response && isHomeAbsenceResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postHomeAbsence(
    request: PostHomeAbsenceRequest,
  ): Promise<HomeAbsenceResponse[]> {
    try {
      const response = await ApiClient.post(`/chats/absence`, request);
      if (isHomeAbsencesResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchChatsChoices(): Promise<ChatChoiceResponse> {
    try {
      const response = await ApiClient.get(`/chats/choices`, null);
      if (isChatChoiceResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchChatsPickup(
    request: FetchHomePickupRequest,
  ): Promise<HomePickupResponse> {
    try {
      const response = await ApiClient.get(`/chats/pickup`, request);
      if (isHomePickupResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async putChatsPickup(
    request: PutHomePickupRequest,
  ): Promise<HomePickupResponse> {
    try {
      const response = await ApiClient.put(`/chats/pickup`, request);
      if (isHomePickupResponse(response)) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async postChatsConfirm(
    request: PostChatsConfirmRequest,
  ): Promise<HomeChatResponse[]> {
    try {
      const response = await ApiClient.post(`/chats/confirm`, request);
      if (response && response.chats && isHomeChatsResponse(response.chats)) {
        return response.chats;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteChat(
    request: DeleteChatsRequest,
  ): Promise<HomeChatResponse[]> {
    try {
      const response = await ApiClient.delete(`/chats/${request.chatId}`, null);
      if (response.message === 'ok') {
        return response.message;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteAbsence(
    request: DeleteAbsenceRequest,
  ): Promise<HomeChatResponse[]> {
    try {
      const response = await ApiClient.delete(`/chats/absence`, request);
      if (response.message === 'ok') {
        return response.message;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async deletePickup(
    request: DeletePickupRequest,
  ): Promise<HomeChatResponse[]> {
    try {
      const response = await ApiClient.delete(`/chats/pickup`, request);
      if (response.message === 'ok') {
        return response.message;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }
}
